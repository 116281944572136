import React, { useState, useEffect } from 'react';
import {useHistory} from "react-router-dom";
import {Link} from "gatsby";
function PricingTables() {
  const history = useHistory();

  const [value, setValue] = useState(true);

  const [priceOutput] = useState({
    plan1: {
      false: ['$', '0', '/month'],
      true: ['$', '0', '/month']
    },
    plan2: {
      false: ['$', '1', '/month'],
      true: ['$', '1', '/month']
    },
    plan3: {
      false: ['$', '59', '/month'],
      true: ['$', '49', '/month']
    },
    plan4: {
      false: ['$', '109', '/month'],
      true: ['$', '99', '/month']
    }
  });

  useEffect(() => {
    const script = document.createElement('script');
  
    // script.src = "https://checkoutlib.billsby.com/checkout.min.js";
    // script.setAttribute(
    //   "data-billsby-company",
    //   "inixia"
    // );
    // script.async = true;
    // script.setAttribute(
    //   "async",
    //   "true"
    // );
    // document.body.appendChild(script);

  });

  const onSubscribeBtnClick = (e) => {
    e.preventDefault()
    console.log('onclick..')
    history.push("/join-waitlist");
  }

  return (
    <section className="bg-gradient-to-b from-white to-gray-100">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pb-12 md:pt-8 md:pb-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12">
            <h1 className="h1 mb-4 text-4xl font-extrabold leading-tight tracking-tighter;" data-aos="zoom-y-out">Pricing</h1>
            {/* <p className="text-xl text-gray-600" data-aos="zoom-y-out" data-aos-delay="150">We offer transparent, simple plans, whether you want to get started for free or avail of premium features. To get started, just choose from a plan below.</p> */}
            <p className="text-xl text-gray-600 mb-4" data-aos="zoom-y-out" data-aos-delay="150">
              Inixia will always be free, but a paid plan is available to those who wish to access Premium templates and download unlimited designs per month.</p>

          </div>

          {/* Pricing tables */}
          <div>

            {/* Pricing toggle */}
            {/* <div className="flex justify-center max-w-xs m-auto mb-16" data-aos="zoom-y-out" data-aos-delay="300">
              <div className="relative flex w-full mx-6 p-1 bg-gray-200 rounded">
                <span
                  className="absolute inset-0 m-1 pointer-events-none"
                  aria-hidden="true"
                >
                  <span className={`absolute inset-0 w-1/2 bg-white rounded shadow transform transition duration-150 ease-in-out ${value ? 'translate-x-0' : 'translate-x-full'}`}></span>
                </span>
                <button
                  className={`relative flex-1 text-sm font-medium p-1 transition duration-150 ease-in-out ${!value && 'text-gray-500'}
                  `}
                  onClick={(e) => { e.preventDefault(); setValue(true); }}
                >Bill Yearly <span className="text-green-500">-20%</span>
                </button>
                <button
                  className={`relative flex-1 text-sm font-medium p-1 transition duration-150 ease-in-out ${value && 'text-gray-500'}`}
                  onClick={(e) => { e.preventDefault(); setValue(false); }}
                >Bill Monthly</button>
              </div>
            </div> */}

            <div className="max-w-sm md:max-w-2xl xl:max-w-none mx-auto grid gap-8 md:grid-cols-2 xl:grid-cols-2 xl:gap-6 items-start">

              {/* Pricing table 1 */}
              <div className="relative flex flex-col h-full py-5 px-6 rounded bg-white shadow-xl" data-aos="zoom-y-out" data-aos-delay="450">
                <div className="mb-4">
                  <div className="text-lg font-bold mb-1">Starter</div>
                  <div className="inline-flex items-baseline mb-2">
                    <span className="text-3xl font-bold">{priceOutput.plan1[value][0]}</span>
                    <span className="text-3xl font-bold">{priceOutput.plan1[value][1]}</span>
                    <span className="text-gray-600 pl-2">{priceOutput.plan1[value][2]}</span>
                  </div>
                  <div className="text-lg text-gray-800">A starter plan that'll always be free</div>
                </div>
                <ul className="text-gray-600 -mb-2 flex-grow">
                  <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Get access to starter templates</span>
                  </li>
                  <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Limited number of designs per month</span>
                  </li>
                  {/* <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Dedicated support</span>
                  </li> */}
                </ul>
                <div className="border-t border-gray-200 pt-5 mt-6">
                  <a href="https://app.websitebackgroundmaker.com" className="btn-sm text-white bg-blue-600 hover:bg-blue-700 w-full font-medium inline-flex items-center justify-center border border-transparent rounded leading-snug transition duration-150 ease-in-out px-4 py-2 shadow">
                    Get Started</a>
                </div>
              </div>

              {/* Pricing table 2 */}
              <div className="relative flex flex-col h-full py-5 px-6 rounded shadow-xl border-2 border-blue-500" data-aos="zoom-y-out" data-aos-delay="450">
                <div className="absolute top-0 right-0 mr-5 p-3 -mt-5 bg-yellow-500 rounded-full">
                  <svg className="w-4 h-4 fill-current text-white" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.145 5.05l-4.316-.627L8.898.513c-.338-.684-1.456-.684-1.794 0l-1.93 3.91-4.317.627a1.002 1.002 0 00-.554 1.707l3.124 3.044-.737 4.3a1 1 0 001.45 1.053L8 13.125l3.862 2.03c.728.381 1.59-.234 1.45-1.054l-.736-4.299L15.7 6.758a1.003 1.003 0 00-.555-1.708z" />
                  </svg>
                </div>
                <div className="mb-4">
                  <div className="text-lg font-bold mb-1">Pro</div>
                  <div className="inline-flex items-baseline mb-2">
                    <span className="text-3xl font-bold">{priceOutput.plan2[value][0]}</span>
                    <span className="text-3xl font-bold">22</span>
                    <span className="text-gray-600 pl-2">/ lifetime</span>
                  </div>
                  <div className="text-lg text-gray-800">Get 100+  backgrounds for both commercial and personal websites.</div>
                </div>
                <ul className="text-gray-600 -mb-2 flex-grow">
                  <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Access to 100+ premium templates</span>
                  </li>
                  <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Unlimited designs</span>
                  </li>
                  <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Assistance with background integration to your website</span>
                  </li>
                  <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Dedicated support</span>
                  </li>
                </ul>
                <div className="border-t border-gray-200 pt-5 mt-6">
                {/* <button onClick={onSubscribeBtnClick} data-billsby-type="checkout" 
                className="btn-sm text-white bg-blue-600 hover:bg-blue-700 w-full pointer">Register Interest</button> */}
                <a href="https://isotopeui.com/?ref=websitebackgroundmaker" 
                className="btn-sm text-white bg-blue-600 hover:bg-blue-700 w-full pointer font-medium inline-flex items-center 
                justify-center border border-transparent rounded leading-snug transition duration-150 ease-in-out px-4 py-2 shadow;">
                  Get Premium Backgrounds</a>

                </div>
              </div>

            </div>

          </div>

        </div >
      </div >
    </section >
  );
}

export default PricingTables;
