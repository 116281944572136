import React from 'react';
import {Link} from "gatsby";

function Cta() {
  return (
    <section className="bg-white">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pb-12 md:pb-20">

          {/* CTA box */}
          <div className="bg-blue-600 rounded py-10 px-8 md:py-16 md:px-12 shadow-2xl" data-aos="zoom-y-out">

            <div className="flex flex-col lg:flex-row justify-between items-center">

              {/* CTA content */}
              <div className="mb-6 lg:mr-16 lg:mb-0 text-center lg:text-left">
                {/* <h3 className="h3 text-white mb-2">Ready to get started?</h3> */}
                <h3 className="h3 text-white mb-2">Get Premium Backgrounds</h3>

                <p className="text-white text-lg opacity-75">Gain access to over 100 premium backgrounds for your commercial & personal sites,
                 plus get a 20% off discount with coupon code ORDER20.</p>
              </div>

              {/* CTA button */}
              <div className="btn bg-white justify-center border border-transparent rounded items-center leading-snug inline-flex items-center justify-center border border-transparent rounded leading-snug transition duration-150 ease-in-out px-1 py-2 shadow">
                <a className="p-4 font-medium inline-flex" href="https://www.isotopeui.com?ref=websitebackgroundmaker">Get Access</a>
              </div>

            </div>

          </div>

        </div>
      </div>
    </section>
  );
}

export default Cta;
